import { refreshNoteList, GetSelectedProcess, onChangeProcess, EmailMessageId } from '@igps/top-utility';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetTaskBasicInfo, TaskBasicInfoEvent } from '@igps/file-info-header';
import { finalize, Subscription } from 'rxjs';
import { TaskBasicInfoDataModel } from '../../models/taskBasicInfoDataModel';
import { NotesService } from '../../../services/notes-service';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { PopoutService } from '../../services/popout.service';
import { NotesPopoutData, PopoutData, PopoutModalName, POPOUT_MODALS } from '../../services/popout.tokens';
import { showSpinnerEvent, showToastEvent } from '../../app.component';
import { CreateEmailNote } from '../../../models/create-note';
import { Process } from '../../../models/Process';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  public notesData = '';
  basicTaskInfo!: TaskBasicInfoDataModel;
  public taskSource! :number;
  onSelectedProcessChangeSubscription!: Subscription;
  taskInformationSubscription$!: Subscription;

  constructor(public dialog: MatDialog, private notesService: NotesService, private popoutService: PopoutService) { }

  ngOnDestroy(): void {
    this.taskInformationSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe(data => {
      if (this.basicTaskInfo?.id !== data.taskinfo?.id) {
        this.basicTaskInfo = data.taskinfo;
      }
    });
    if (!this.basicTaskInfo) {
      this.basicTaskInfo = GetTaskBasicInfo();
    }
    this.onSelectedProcessChangeSubscription = onChangeProcess.subscribe((process: Process) => {
      this.taskSource = process.taskSource;
    });

    if (this.taskSource === undefined) {
      const process = GetSelectedProcess();
      if (process?.id) {
        this.taskSource = process.taskSource
      }
    }
  }
  onPopOutNoteClick() {
    const modalData: PopoutData<NotesPopoutData> = {
      modalName: PopoutModalName.taskNotes,
      basicTaskInfo: this.basicTaskInfo,
      data: {
        notes: this.notesData,
      }
    };
    if (!this.popoutService.isPopoutWindowOpen(PopoutModalName.taskNotes)) {
      this.popoutService.openPopoutModal(modalData);
    } else {
      POPOUT_MODALS[PopoutModalName.taskNotes].outlet.detach();
      const injector = this.popoutService.createInjector(modalData);
      const componentInstance = this.popoutService.attachNotesContainer(POPOUT_MODALS[PopoutModalName.taskNotes].outlet, injector);
      POPOUT_MODALS[PopoutModalName.taskNotes].componentInstance = componentInstance;
      this.popoutService.focusPopoutWindow(PopoutModalName.taskNotes);
    }

    // this.openAddNotesDialog();
  }
  public onaddNotes() {
    const body: { Notes: string } = {
      Notes: this.notesData.trim().replace(/\n\n/g, '\n')
    };
    showSpinnerEvent.emit(true);
    if(this.taskSource == 1){
    this.notesService.saveNotes(this.basicTaskInfo.id, body).pipe(
      finalize(() => {
        showSpinnerEvent.emit(false);
      })
    ).subscribe({
      next: () => {
        this.notesData = "";
        showToastEvent.emit({ message: "Note Added Successfully.", isError: false});
        refreshNoteList.emit(true);
      },
      error: (error: HttpErrorResponse) => {
        showToastEvent.emit({ message: error?.error.Detail, isError: true });
      }
    });
  }
  else{
    const emailMessageId = sessionStorage.getItem('selectedEmailNoteTaskId');
    const createEmailNote: CreateEmailNote = {
      emailId: String(emailMessageId),
      notes: this.notesData.replace(/\n\n/g, '\n')
    };
    this.notesService.saveEmailNotes(emailMessageId, createEmailNote).pipe(
      finalize(() => {
        showSpinnerEvent.emit(false);
      })
    ).subscribe({
      next: () => {
        this.notesData = "";
        showToastEvent.emit({ message: "Note Added Successfully.", isError: false});
        EmailMessageId.emit(emailMessageId); 
      },
      error: (error: HttpErrorResponse) => {
        showToastEvent.emit({ message: error?.error.Detail, isError: true });
      }
    });
  
  }
  }

  openAddNotesDialog(): void {
    const dialogRef = this.dialog.open(AddNotesComponent, {
      panelClass: 'ig2',
      width: '500px',
      disableClose: true,
      data: {
        notes: this.notesData,
        basicTaskInfo: this.basicTaskInfo
      },
    });
    dialogRef.afterClosed().subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: Event) {
    if (this.popoutService.isPopoutWindowOpen(PopoutModalName.taskNotes)) {
      this.popoutService.focusPopoutWindow(PopoutModalName.taskNotes);
      event.returnValue = false;
      this.popoutService.focusPopoutWindow(PopoutModalName.taskNotes);
      return false;
    }
    else {
      return true;
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    if (this.popoutService.isPopoutWindowOpen(PopoutModalName.taskNotes)) {
      this.popoutService.closePopoutModal(PopoutModalName.taskNotes);
    }
  }

}
